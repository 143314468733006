import { BrowserRouter as Router, Route } from 'react-router-dom'


//import logo from './logo.svg'
import './App.css'

import NoMatch from './components/NoMatch'


function App() {
  return (
    <Router>
      <Route
          path='/'
          exact
          render={(props) => (
            <div className="App">
              <header className="App-header">
                <a className="App-link" href="https://medium.com/gallardolabs-livingroom" alt="Gallardo Labs">
                  <img src="/TheLivingRoom.png"  className="App-logo" alt="The Livingroom"></img>
                </a>
              </header>
            </div>
       )} />
      <Route component={NoMatch} />
    </Router>
  )
}

/*              <a className="App-link" href="https://www.gallardolabs.com" alt="Gallardo Labs">
                  <img src={logo} className="App-logo" alt="logo" />
                </a>
*/

export default App
