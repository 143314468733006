import {useLocation} from "react-router-dom"
import {useEffect} from "react";

//import logo from '../logo.svg'
import '../App.css'

//Components


//Hooks


//Addresses
const redirects = {}

    redirects["https://practicesafevoting.gallardolabs.com/"] = "https://www.gallardolabs.com/case/4ymNf4kAPN8fM3rZiNuR5z"  //"https://practicesafevoting.org/"

    redirects["/2020/05/04/spaces/"] = "https://medium.com/gallardolabs-livingroom/spaces-8ac4384c04b2"
	redirects["/2020/05/13/recipe-to-be-the-best-at-anything/"] = "https://medium.com/gallardolabs-livingroom/recipe-to-be-the-best-at-anything-41282742a4e6"
	redirects["/2020/05/26/wfh-can-change-the-world/"] = "https://medium.com/gallardolabs-livingroom/wfh-can-change-the-world-c4130e01bf5e"
	redirects["/2021/01/27/2021-global-design-trends/"] = "https://medium.com/gallardolabs-livingroom/2021-global-design-trends-477451054c28"
	redirects["/2020/06/26/meet-the-team-greg-hernandez/"] = "https://medium.com/gallardolabs-livingroom/meet-the-team-greg-hernandez-7d3b46aeea84"
	redirects["/2019/05/10/meet-the-team-gallardo-labs-nicole-gallardo/"] = "https://medium.com/gallardolabs-livingroom/meet-the-team-nicole-gallardo-a07a9dd7cf38"
	redirects["/2020/10/15/was-bette-midler-a-prophet-and-will-business-greetings-be-changed-forever/"] = "https://medium.com/gallardolabs-livingroom/was-bette-midler-a-prophet-and-will-business-greetings-be-changed-forever-6121f349b1b0"
	redirects["/2020/07/19/practice-safe-voting/"] = "https://medium.com/gallardolabs-livingroom/practice-safe-voting-14d77e9ec825"
	redirects["/2020/06/11/effective-ux-design-step-1/"] = "https://medium.com/gallardolabs-livingroom/effective-user-experience-design-discovery-phase-8de74c7c61bd"
	redirects["/2020/03/28/how-to-have-a-virtual-team-happy-hour/"] = "https://medium.com/gallardolabs-livingroom/how-to-have-a-virtual-team-happy-hour-81b3324c21e6"
	redirects["/2019/08/30/simple-tips-for-developing-a-brand-voice-tone/"] = "https://medium.com/gallardolabs-livingroom/simple-tips-for-developing-a-brand-voice-tone-d18a38aff8ce"
	redirects["/2019/06/25/tips-for-working-remotely/"] = "https://medium.com/gallardolabs-livingroom/8-tips-for-working-remotely-4901c409d54b"
	redirects["/2019/10/18/what-its-really-like-to-be-a-woman-in-tech-part-2/"] = "https://medium.com/gallardolabs-livingroom/what-its-really-like-to-be-a-woman-in-tech-part-2-nicole-s-view-3575f6b7f6b0"
	redirects["/2019/07/16/62-industry-acronyms-every-ux-designer-should-know/"] = "https://medium.com/gallardolabs-livingroom/industry-acronyms-every-ux-designer-should-know-7d7e4b889032"
	redirects["/2019/06/18/5-things-i-wish-someone-had-told-me-about-working-as-a-creative-professional/"] = "https://medium.com/gallardolabs-livingroom/5-things-i-wish-someone-had-told-me-about-working-as-a-creative-professional-de7c0e67d69d"
	redirects["/2019/05/30/our-fab-five-tools-how-we-achieve-maximum-efficiency/"] = "https://medium.com/gallardolabs-livingroom/our-fab-five-tools-how-we-achieve-maximum-efficiency-1fb7c782c1b6"
	redirects["/2019/05/11/how-to-conduct-a-successful-stakeholder-interview/"] = "https://medium.com/gallardolabs-livingroom/how-to-conduct-a-successful-stakeholder-interview-b4b7e8c3c9ba"
	redirects["/2019/05/10/good-ux-design-roi/"] = "https://medium.com/gallardolabs-livingroom/the-dl-on-roi-ux-a6ae54eefeed"
	redirects["/2021/02/12/how-to-get-yourself-noticed-and-make-a-mean-cookie/"] = "https://medium.com/gallardolabs-livingroom/how-to-get-yourself-noticed-and-make-a-mean-cookie-9c7abb51e0ef"
	redirects["/2020/04/19/on-being-a-mom-during-covid19/"] = "https://medium.com/gallardolabs-livingroom/on-being-a-mom-during-covid19-95620f0e8fc0"
	redirects["/2020/04/17/when-this-is-over/"] = "https://medium.com/gallardolabs-livingroom/when-this-is-over-ec62ab533227"
	redirects["/2020/02/10/what-its-really-like-to-be-a-woman-in-tech-part-3-karos-view/"] = "https://medium.com/gallardolabs-livingroom/what-its-really-like-to-be-a-woman-in-tech-part-3-karo-s-view-8848efdfdc7e"
	redirects["/2019/11/25/why-storytelling-is-essential-to-building-a-successful-brand/"] = "https://medium.com/gallardolabs-livingroom/why-storytelling-is-essential-to-building-a-successful-brand-f31ea1d793a8"
	redirects["/2019/10/20/gallardo-labs-attends-forbes-under-30-summit/"] = "https://medium.com/gallardolabs-livingroom/gallardo-labs-attends-forbes-under-30-summit-2e43bfb2fa6d"
    redirects["/2019/07/09/how-to-give-feedback-thats-actually-helpful/"] = "https://medium.com/gallardolabs-livingroom/how-to-give-feedback-thats-actually-helpful-127520367023"
	redirects["/2019/05/09/gallardo-labs-digital-agency-new-blog-welcome/"] = "https://medium.com/gallardolabs-livingroom/welcome-to-the-gallardo-labs-livingroom-b76be635ced7"
	redirects["/2021/02/08/our-2021-new-years-resolutions-ux-edition/"] = "https://medium.com/gallardolabs-livingroom/our-2021-new-years-resolutions-ux-edition-14bff6b3002e"
	redirects["/2020/12/04/our-5-favorite-things-this-season/"] = "https://medium.com/gallardolabs-livingroom/our-5-favorite-things-this-season-6836c6ade728"
	redirects["/2020/11/23/meet-the-team-sam-cabrera/"] = "https://medium.com/gallardolabs-livingroom/meet-the-team-sam-cabrera-e69675f51391"
	redirects["/2020/11/11/history-in-the-making-from-the-eyes-of-a-4yr-old/"] = "https://medium.com/gallardolabs-livingroom/history-in-the-making-from-the-eyes-of-a-4yr-old-cc5c53c16293"
	redirects["/2020/06/21/lessons-my-dad-taught-me/"] = "https://medium.com/gallardolabs-livingroom/lessons-my-dad-taught-me-a27d042d3d65"
	redirects["/2020/02/24/do-you-some-thoughts-on-growing-into-a-leader/"] = "https://medium.com/gallardolabs-livingroom/do-you-some-thoughts-on-growing-into-a-leader-2993e29f5bae"
	redirects["/2019/06/14/spotlight-on-the-dads-of-gallardo-labs/"] = "https://medium.com/gallardolabs-livingroom/spotlight-on-the-dads-of-gallardo-labs-46e079847d5d"
	redirects["/2019/05/11/how-gallardo-labs-moms-make-it-work/"] = "https://medium.com/gallardolabs-livingroom/how-gallardo-labs-moms-make-it-work-4b7a522e1b61"
	redirects["/2019/05/10/meet-the-team-gallardo-labs-sonia-acosta/"] = "https://medium.com/gallardolabs-livingroom/meet-the-team-sonia-acosta-fcdb09870407"
	redirects["/2019/05/10/meet-the-gallardo-labs-team-daniel-gonzalez/"] = "https://medium.com/gallardolabs-livingroom/meet-the-team-daniel-gonzalez-9aeca80fae26"
	redirects["/2019/05/09/meet-the-team-gallardo-labs-carolina-munoz/"] = "https://medium.com/gallardolabs-livingroom/meet-the-team-carolina-mu%C3%B1oz-aka-karo-a969d8f9c9db"
	redirects["/2019/05/09/gallardo-labs-meet-the-team-lore-zeledon/"] = "https://medium.com/gallardolabs-livingroom/meet-the-team-lore-zeledon-b8903aea4727"
	redirects["/2019/05/09/meet-the-team-gallardo-labs-nura-othman/"] = "https://medium.com/gallardolabs-livingroom/meet-the-team-nura-othman-gallardo-labs-blog-fdda39b2e05e"
	redirects["/2019/05/09/meet-the-team-gallardo-labs-reid-uhrich/"] = "https://medium.com/gallardolabs-livingroom/meet-the-team-reid-uhrich-65dadacfced9"



function NoMatch () {
   
    const location = useLocation()  





    useEffect(() => {

    
        
        //const { search } = location

        //console.log(redirects)
        console.log(location)
        console.log(location.pathname)
        console.log(window.location.href)
        //console.log(search)

        console.log(redirects[location.pathname])

        if(window.location.href.indexOf("practicesafevoting.gallardolabs.com") !== -1 )
            window.location.href = redirects["https://practicesafevoting.gallardolabs.com/"]
        else if(redirects[location.pathname])
            window.location.href = redirects[location.pathname]



    }, [location]);



    return(
	  <div className="App">
		<header className="App-header">
			<a className="App-link" href="https://medium.com/gallardolabs-livingroom" alt="Gallardo Labs">
				<img src="/TheLivingRoom.png"  className="App-logo" alt="The Livingroom"></img>
			</a>
		</header>
	  </div>
)
}

export default NoMatch



